<template>
  <el-container>
    <el-aside width="auto">
      <common-aside />
    </el-aside>
    <el-container>
      <el-header><common-header /></el-header>

      <el-scrollbar style="height: calc(100vh - 60px)">
        <el-main>
          <el-breadcrumb
              style="margin-bottom:12px"
              v-if="$route.path !== '/index'"
              separator-class="el-icon-arrow-right"
            >
              <el-breadcrumb-item
                v-for="item in $route.matched"
                :to="{ path: '/index' }"
                >{{ item.name }}</el-breadcrumb-item
              >
            </el-breadcrumb>
          <transition name="fade-transform" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-main>
      </el-scrollbar>
    </el-container>
    <projOperation v-if="$route.path !== '/index'"></projOperation>
  </el-container>
</template>

<script>
import CommonAside from "../../components/CommonAside.vue";
import CommonHeader from "../../components/CommonHeader.vue";
import projOperation from "@/components/projOperation.vue";
export default {
  name: "HomePage",
  components: { CommonAside, CommonHeader, projOperation },
  data() {
    return {
      isCollapse: false,
    };
  },
};
</script>

<style scoped lang="less">
.el-header {
  padding: 0;
}

.el-aside::-webkit-scrollbar {
  display: none;
}
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
