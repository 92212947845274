<template>
  <div class="header-container">
    <div class="l-content">
      <el-button
        @click="handleMenu"
        icon="el-icon-menu"
        size="mini"
      ></el-button>
      <!-- 面包屑 -->
      <span class="text"
        ><router-link to="/index">第五人格ASG赛事后台管理系统v4.1.0</router-link></span
      >
    </div>
    <div class="r-content">
      <el-dropdown>
        <span class="el-dropdown-link">
          <el-image class="user" :src="imgUrl">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            ><router-link to="/index/information">管理中心</router-link></el-dropdown-item
          >
          <el-dropdown-item
            ><span @click="handleUpdate">修改密码</span></el-dropdown-item
          >
          <el-dropdown-item><a @click="logout">退出</a></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="500px"
      @close="closeD('form')"
      @open="openD"
    >
      <div class="form-body">
        <el-form ref="form" :model="form" :rules="rules" label-position="top">
          <el-form-item label="请输入邮箱" prop="email">
              <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="请输入新密码" prop="password">
            <div style="display: flex; gap: 20px; align-items: center">
              <el-input type="password" v-model="form.password" show-password></el-input>
              <el-button @click.prevent="getToken('form')" 
              :disabled="showToken"
              v-loading="changeLoading"
                >获取验证</el-button
              >
            </div>
          </el-form-item>
          <transition name="run">
            <el-form-item label="请输入邮箱验证码" v-show="showToken">
              <div style="display: flex; gap: 20px; align-items: center">
               <el-input v-model="form.token"></el-input>
               <el-button @click="confirm" type="success">确认更换</el-button>
             </div>
             </el-form-item>
          </transition>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updatePassword, confirmUpdate } from "@/api/login/index.js";
export default {
  name: "CommonHeader",
  data() {
    return {
      dialogVisible: false,
      form: {
        email: "",
        password:"",
        token: "",
      },
      showToken: false,
      changeLoading:false,
      rules: {
        email: [
          { required: true, message: '请填写邮箱地址', trigger: 'blur' }
         ],
         password:[
          { required: true, message: '请填写新密码', trigger: 'blur' }
         ]
        }
    };
  },
  computed:{
    imgUrl(){
     return this.$store.state.userInfo.base64 || sessionStorage.getItem("baseImg")
    }
  },
  methods: {
    logout() {
      this.$store.commit("removeToken");
      sessionStorage.removeItem('baseImg');
      this.$router.push("/");
      this.$message.warning("您已退出登录！");
    },
    handleMenu() {
      this.$store.commit("collapseMenu");
    },
    handleUpdate() {
      this.dialogVisible = true;
    },
    getToken(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changeLoading = true
          updatePassword(this.form.email)
          .then(() => {
            this.$message.success("发送成功,请输入验证码");
            this.showToken = true;
            this.changeLoading = false
          })
          .catch(err=>{
            this.$message.error(err.message)
            this.changeLoading = false
            })
        } else {
          this.$message.error("请完整填写表单内容！");
        }
      });
    },
    confirm() {
      if(this.form.token){
        confirmUpdate(this.form.email,this.form.password,this.form.token)
        .then(() => {
          this.$message.success("修改成功,请重新登录！");
          this.$store.commit("removeToken");
          this.$router.push("/");
         })
         .catch((err)=>{
            this.$message.error(err.message)
         })
      }else{
        this.$message.error("请输入验证码！")
      }
      
    },
    closeD(formName){
      this.dialogVisible = false
      this.showToken = false
      this.form.token = ''
      this.$refs[formName].resetFields();
    },
    openD(){
       this.form.email = sessionStorage.getItem("email") || ''
     }
  },
};
</script>

<style scoped lang="less">
.header-container {
  padding: 0 20px;
  background-color: #0D47A1;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    a {
      color: #fff;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .r-content {
    .user {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
span {
  width: 100%;
}
.form-body {
  padding: 0px 50px 20px;
}
.run-enter-active { 
   animation: bounce-in .4s; 
} 
/* 离开的时候要激活的样式 */
.run-leave-active { 
   animation: bounce-in .4s reverse;
}
@keyframes bounce-in {
   0% { 
      transform: scale(0); 
   } 
   50% { 
      transform: scale(1.3);
   } 
   100% { 
      transform: scale(1); 
   }
}
</style>
