<template>
  <el-menu
    :collapse="isCollapse"
    :default-active="$route.path"
    class="el-menu-vertical-demo"
    background-color="#0089f3"
    text-color="#e3f2fd"
    active-text-color="#f7e157"
    router
    :unique-opened="true"
  >
    <h3>{{ isCollapse ? "ASG" : "ASG后台管理系统" }}</h3>
    <el-menu-item index="/index">
      <svg-icon
        style="margin-right: 5px"
        iconClass="home"
        width="20px"
        height="20px"
        color="#e7e7e7"
      ></svg-icon>
      <span slot="title">首页</span>
    </el-menu-item>
    <el-menu-item index="/index/information">
      <svg-icon
        style="margin-right: 5px"
        iconClass="info"
        width="20px"
        height="20px"
        color="#e7e7e7"
      ></svg-icon>
      <span slot="title">个人中心</span>
    </el-menu-item>
    <el-menu-item index="/index/person">
      <svg-icon
        style="margin-right: 5px"
        iconClass="person"
        width="20px"
        height="20px"
        color="#e7e7e7"
      ></svg-icon>
      <span slot="title">人员管理</span>
    </el-menu-item>
    <el-submenu index="/index/enroll">
      <template slot="title">
        <svg-icon
          style="margin-right: 5px"
          iconClass="game"
          width="20px"
          height="20px"
          color="#e7e7e7"
        ></svg-icon>
        <span>赛事管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/index/enroll">
          <svg-icon
            style="margin-right: 5px"
            iconClass="enroll"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          报名管理
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/schedule">
          <svg-icon
            style="margin-right: 5px"
            iconClass="schedule"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          赛程管理
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/ballot">
          <svg-icon
            style="margin-right: 5px"
            iconClass="extract"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          抽签管理
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/gamepic">
          <svg-icon
            style="margin-right: 5px"
            iconClass="gamepic"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          对战图制作
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/test">
          <svg-icon
            style="margin-right: 5px"
            iconClass="excel"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          导出表单
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="/index/blok">
      <template slot="title">
        <svg-icon
          style="margin-right: 5px"
          iconClass="publish"
          width="20px"
          height="20px"
          color="#e7e7e7"
        ></svg-icon>
        <span>发布管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/index/blok">
          <svg-icon
            style="margin-right: 5px"
            iconClass="champion"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          冠军管理
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/news">
          <svg-icon
            style="margin-right: 5px"
            iconClass="text"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          公告管理
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/private">
          <svg-icon
            style="margin-right: 5px"
            iconClass="private"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          自动化发布
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item index="/index/drag">
      <svg-icon
        style="margin-right: 5px"
        iconClass="promote"
        width="20px"
        height="20px"
        color="#e7e7e7"
      ></svg-icon>
      <span slot="title">晋升图</span>
    </el-menu-item>
    <el-menu-item index="/index/banpick">
      <svg-icon
        style="margin-right: 5px"
        iconClass="whiteblack"
        width="20px"
        height="20px"
        color="#e7e7e7"
      ></svg-icon>
      <span slot="title">黑白名单</span>
    </el-menu-item>
    <el-submenu index="/index/workflow">
      <template slot="title">
        <svg-icon
          style="margin-right: 5px"
          iconClass="system"
          width="20px"
          height="20px"
          color="#e7e7e7"
        ></svg-icon>
        <span>系统参数</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/index/workflow">
          <svg-icon
            style="margin-right: 5px"
            iconClass="assign"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          <span slot="title">工作流</span>
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/globalVar">
          <svg-icon
            style="margin-right: 5px"
            iconClass="json"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          <span slot="title">云端配置</span>
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/exchange">
          <svg-icon
            style="margin-right: 5px"
            iconClass="exchange"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          <span slot="title">兑换中心</span>
        </el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/index/process">
          <svg-icon
            style="margin-right: 5px"
            iconClass="server"
            width="16px"
            height="16px"
            color="#e7e7e7"
          ></svg-icon>
          <span slot="title">服务器管理</span>
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  name: "",
  computed: {
    isCollapse() {
      return this.$store.state.isCollapse;
    },
  },
};
</script>

<style scoped lang="less">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  height: 100vh;
  border-right: none;
  h3 {
    color: #fff;
    text-align: center;
  }
}
/deep/.el-submenu__title i {
  color: #f9f9f9;
}
</style>
