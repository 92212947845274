<template>
  <div ref="container" class="online_container">
    <div class="online_icon" @click="showPhone">
      <svg-icon
        iconClass="project-collection"
        width="22px"
        height="22px"
        color="#e7e7e7"
      ></svg-icon>
      任务卡
    </div>
    <div class="online_windows">
      <h3>我的任务清单</h3>
      <ul class="list">
        <li :class="item.status" v-for="item in list">
          <p>{{ item.label }}</p>
          <p>{{ item.value}}</p>
        </li>
      </ul>
      <h3>购物信息</h3>
      <p>
        我的金币:<span style="color: rgb(255, 133, 46); font-weight: bold"
          >{{ money }}</span
        >
      </p>
      <footer @click="$router.push('/index/exchange');showPhone()">
        前往兑换中心
        <svg-icon
          iconClass="router"
          width="16px"
          height="16px"
          color="#000"
        ></svg-icon>
      </footer>
    </div>
  </div>
</template>

<script>
import { getByTitle } from "@/api/config";
export default {
  name: "projOperation",
  data() {
    return {
      toggle: true,
      list: []
    };
  },
  computed:{
    money(){
      return this.$store.state.userInfo?.integral || sessionStorage.getItem("integral") || 0
    }
  },
  created(){
    this.initAssign();
  },
  methods: {
    showPhone() {
      this.$refs.container.style.right = this.toggle ? 0 : "-340px";
      this.toggle = !this.toggle;
    },
    initAssign() {
      getByTitle("assignList")
        .then((res) => {
          this.list = res.data;
        })
        .catch((err) => {
          this.$message.error("获取云端配置失败！");
        });
    },
  },
};
</script>

<style scoped lang="less">
.online_container {
  display: flex;
  position: absolute;
  right: -340px;
  top: 40vh;
  transition: all 0.5s;
  z-index: 999;
}
.online_icon {
  width: 46px;
  background: rgba(235, 235, 235, 0.8);
  border-radius: 10px 0 0 10px;
  height: 120px;
  cursor: pointer;
  padding: 10px 0 20px 0;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 26px;
  width: 30px;
  color: #000000;
  word-break: break-all;
  svg {
    font-size: 28px;
    margin-bottom: 10px;
  }
}
.online_windows {
  position: relative;
  height: 60vh;
  width: 320px;
  transform: translateY(-20vh);
  background: #f2f2f2;
  border: 1px solid #e7e7e7;
  background-color: rgba(225, 225, 225, 0.8);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  footer {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    height: 24px;
  }
}
h3 {
  margin-bottom: 24px;
}
.list {
  min-height: 150px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #fff;
  li {
    display: flex;
    justify-content: space-between;
    height: 24px;
    padding: 6px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      border-color: #7894e0;
    }
  }
}
.success {
  color: rgb(66, 203, 56);
}
.error {
  color: rgb(223, 70, 59);
}
</style>
